import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index/:id?',
        name: 'index',
        component: () =>import ('../views/index')
    },
	{
	    path: '/contact/:id?',
	    name: 'contact',
	    component: () =>import ('../views/contact')
	},
	{
	    path: '/train/:id?',
	    name: 'train',
	    component: () =>import ('../views/train')
	},
	{
	    path: '/ontrial',
	    name: 'ontrial',
	    component: () =>import ('../views/ontrial')
	},
	{
	    path: '/help/:id?',
	    name: 'help',
	    component: () =>import ('../views/help')
	},
	{
	    path: '/download',
	    name: 'download',
	    component: () =>import ('../views/download')
	}
]

const router = new VueRouter({
    routes
})

export default router