import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Promise from 'es6-promise'//npm i es6-promise   --save-dev
import './assets/css/index.css'
import 'amfe-flexible'//npm i amfe-flexible   npm i postcss-px2rem    配置文件postcss
import animated from 'animate.css' // npm install animate.css --save
import axios from 'axios'
import ElementUI from 'element-ui'
import './assets/theme/index.css'
Vue.use(ElementUI)
Vue.use(animated)
Vue.config.productionTip = false;
Promise.polyfill();
axios.defaults.baseURL = 'https://www.dmclkj.com/api';
//axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
